@charset "utf-8";
/*============================================

  TAB

============================================*/
.c-tab{
  $amp: &;
  &__head{
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    border:1px solid $border_color;
    line-height: 1;
    margin-bottom: 4rem;
    li{
      width: calc(100% / 3);
      &:nth-child(n+2){
        border-left: 1px solid $border_color;
      }
    }
    &.-clm2{
      li{
        width: 50%;
      }
    }
    a{
      text-align: center;
      display: block;
      padding: 1.4em 0;
      font-size: 1.8rem;
      @include mq(sm-){
        &:hover,
        &.is-act{
          background: $main_color;
          color: #fff;
          position: relative;
          &:before{
            content: "";
            border-top: 0.8em solid #2b77e0;
            border-right: 0.6em solid transparent;
            border-bottom: 1em solid transparent;
            border-left: 0.6em solid transparent;
            position: absolute;
            bottom: -1.8em;
            left: 50%;
            transform: translateX(-50%);
          }
        }
      }
    }
    @include mq(xs){
      a{
        font-size: 1.4rem;
      }
    }
  }
  &__item{
  }
  &__trg{
    &.is-act{
      pointer-events: none;
    }
  }
  &__body{
  }
  &__conts{
    display: none;
  }
}
