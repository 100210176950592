@charset "utf-8";
/*============================================

  MEGAMENU

============================================*/
.p-megamenu{
  $amp : &;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  display: none;
  z-index: 100;
}
