@charset "utf-8";
/*============================================

  PAGER

============================================*/
.c-pager{
  display: flex;
  justify-content: center;
  align-items: center;
  &__item{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 2em;
    height: 2em;
    background: #fff;
    color: $accent_color;
    &:nth-child(n+2){
      margin-left: .5em;
    }
    &.-current{
      background: $accent_color;
      color:#fff;
    }
    &.-ellipsis{
      background: none;
      color: $font_color;
    }
    &.-link{
      border:solid 1px $accent_color;
    }
    @include mq(sm-){
      &.-link{
        &:hover{
          background: $accent_color;
          color: #fff;
        }

      }
    }
  }
}
