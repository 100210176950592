@charset "utf-8";
/*============================================

  ACCORDION

============================================*/
.c-accordion{
  &__trg{

  }
  &__cont{
    display: none;
  }
}
