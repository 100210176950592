@charset "utf-8";
/*--------------------------------------------
  KEYFRAME
--------------------------------------------*/
@keyframes ripple{
  0%{
    opacity: 1;
    width: 50;
    padding-top: 50;
  }
  16.66666%{
    opacity: 0;
    width: 150%;
    padding-top: 150%;
  }
  100%{
    opacity: 0;
    width: 50%;
    padding-top: 50%;
  }
}
@keyframes draw_path {
  0% {
    stroke-dashoffset: 2000;
    stroke-width: 1;
    fill-opacity:0;
  }

  75% {
    stroke-dashoffset: 0;
    stroke-width: 1;
    fill-opacity: 0;
  }

  100% {
    stroke-dashoffset: 0;
    stroke-width: 0;
    fill-opacity: 1;
  }
}
@keyframes hover_light {
  100% {
    outline-color: transparent;
    outline-offset: .75em;
  }
}
@keyframes scaleUoDown {
  0%{
    transform: scale(1);
  }
  50%{
    transform: scale(0);
  }
  100%{
    transform: scale(1);
  }
}
/*--------------------------------------------
  MIXIN
--------------------------------------------*/
@mixin animation($type: 'fadeUp',$status: 'before'){
  @if $type == 'fadeUp' {
    @if $status == 'before' {
      transition-property:transform,opacity;
      transform:translateY($margin_sm);
      opacity: 0;
    }@else if $status == 'after' {
      transform:translateY(0);
      opacity: 1;
    }
  }
  @else if $type == 'fadeLeft' {
    @if $status == 'before' {
      transition-property: transform, opacity;
      transform:translateX($margin_sm);
      opacity: 0;
    }@else if $status == 'after' {
      transform:translateX(0);
      opacity: 1;
    }
  }

  @else if $type == 'fadeRight' {
    @if $status == 'before' {
      transition-property: transform, opacity;
      transform:translateX(-$margin_sm);
      opacity: 0;
    }@else if $status == 'after' {
      transform:translateX(0);
      opacity: 1;
    }
  }

  @else if $type == 'fadeIn' {
    @if $status == 'before' {
      transition-property: opacity;
      opacity: 0;
    }@else if $status == 'after' {
      opacity: 1;
    }
  }

  @else if $type == 'scaleUp' {
    @if $status == 'before' {
      transition-property: transform;
      transform:scale(0);
    }@else if $status == 'after' {
      transform:scale(1);
    }
  }
}
