@charset "utf-8";
/*============================================

  KV

============================================*/
/*-------------------------------
  KV > TOP
-------------------------------*/
.p-kv{
  font-size: 1rem;
  &__slide{
    position: relative;
    &:not(.slick-initialized){
      li:not(:first-child){
        display: none !important;
      }
    }
  }
  &__figure{
    height: 100vh;
    min-height: 600px;
    position: relative;
    &:before{
      content: "";
      background: #000;
      width: 100%;
      height: 100%;
      display: block;
      position: absolute;
      left: 0;
      top:0;
      opacity: 0.2;
    }
    img{
      object-fit: cover;
      height: 100%;
      width: 100%;
    }
  }
  &__catch{
    position: absolute;
    left: 50%;
    top:50%;
    transform: translate(-50% ,-50%);
    text-align: center;
    width: 100%;
    font-size: 5.6em;
    color: #fff;
    @include mq(sm){
      font-size: 4.6em;
    }
    @include mq(ss){
      font-size: 3em;
    }
  }
}

/*-------------------------------
  KV > UNDER
-------------------------------*/
.p-kv_under{
  position: relative;
  &__img{
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    img{
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
    &:before{
      content: "";
      background: #000;
      width: 100%;
      height: 100%;
      display: block;
      position: absolute;
      left: 0;
      top:0;
      opacity: 0.2;
    }
  }
  &__text{
    position: relative;
    z-index: 2;
    font-size: 4rem;
    font-weight: 500;
    padding: 3.85em 0;
    max-width: 1200px;
    width: 94%;
    margin: auto;
    color: #fff;
    p{
      margin-bottom: 0.2em;
    }
    em{
      font-size: 0.65em;
      font-style: normal;
      font-family: $font_en;
    }
    @include mq(xs){
      font-size: 2.8rem;
      width: 92%;
      padding: 2.5em 0;
    }
  }
}
