@charset "utf-8";
/*============================================

  OTHER COMPONENT

============================================*/
/*-------------------------------
  COMPONENT NAME
-------------------------------*/
.c-read{
  background: $font_color;
  color: #fff;
  padding: 8rem 0;
  @include mq(xs-){
    text-align: center;
  }
  &__ttl{
    font-size: 3rem;
    margin-bottom: 0.7em;
    line-height: 1.3;
  }
  @include mq(sm){
    &__ttl{
      font-size: 2.8rem;
    }
  }
  @include mq(xs){
    padding: 6rem 0;
    &__ttl{
      font-size: 2.2rem;
    }
  }
  &.-wh{
    color: $font_color;
    background: none;
  }
}

.c-flow{
  @include mq(xs-){
    display: flex;
    justify-content: space-between;
    align-items: stretch;
  }
  li{
    padding: 2rem;
    border: 1px solid $border_color;
    position: relative;
    @include mq(xs-){
      width: 23.3%;
      &:not(:first-child){
        &:before{
          content: "";
          border-top: 2rem solid transparent;
          border-bottom: 2rem solid transparent;
          border-left: 1.3rem solid $main_color;
          position: absolute;
          left: -7%;
          top: 50%;
          transform: translateY(-50%);
        }
      }
    }
    @include mq(xs){
      padding: 2em;
      &:nth-child(n+2){
        margin-top: 2em;
      }
    }
  }
  &__img{
    position: relative;
    width: 10rem;
    padding-top: 10rem;
    background: $main_color;
    border-radius: 50%;
    margin: 0 auto 1em;
    svg{
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50% ,-50%);
      height: 5.4rem;
      width: 100%;
      fill:#fff
    }
    @include mq(xs){
      width: 8rem;
      padding-top: 8rem;
      svg{
        height: 4rem;
      }
    }
  }
  &__text{
    em{
      font-size: 2.2rem;
      line-height: 1.4;
      font-style: normal;
      margin-bottom: 0.4em;
      text-align: center;
      display: block;
    }
  }
}

.js-anime{
  opacity: 0;
  transform: translateY(4rem);
  transition-duration: 0.7s;
  &.is-anime{
    opacity: 1;
    transform: translateY(0rem);
  }
}