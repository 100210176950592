@charset "utf-8";
/*============================================

  NRESULT

============================================*/
/*-------------------------------
  NEWS > COMMON
-------------------------------*/

.p-results{
  &__archive{
    padding: 10rem 0;
    @include mq(xs){
      padding: 8rem 0;
    }
  }
  &__list{
    display: flex;
    flex-wrap: wrap;
    li{
      @include mq(xs-){  
        width: 30%;
        margin-left: 5%;
        &:nth-child(3n+1){
          margin-left: 0;
        }
        &:nth-child(4n){
          margin-top: 2em;
        }
      }
      @include mq(xs){
        &:nth-child(n+2){
          margin-top: 3em;
        }
      }
    }
    figure{
      margin-bottom: 0.8em;
      overflow: hidden;
      img{
        transition-duration: 0.3s;
      }
      @include mq(sm-){
        &:hover{
          img{
            transform: scale(1.1);
          }
        }
      }
    }
    span{
      display: inline-block;
      margin-bottom: 0.5em;
      &:before {
        @include icon('arrow-fill_15');
        color: $main_color;
        z-index: 999;
        position: relative;
        margin-right: 0.5em;
      }
    }
    p{
      font-weight: 400;
      line-height: 1.5;
    }
  }
}