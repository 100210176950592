@charset "utf-8";

/*-------------------------------
  WP > EDITOR
-------------------------------*/
.wp-editor {
  word-break: break-all;
  strong {
    font-weight: 700;
  }
  em {
    font-style: italic;
  }
  blockquote {
    display: block;
    -webkit-margin-before: 1em;
    -webkit-margin-after: 1em;
    -webkit-margin-start: 40px;
    -webkit-margin-end: 40px;
  }
  img.alignleft {
    margin: 0 auto 0 0;
  }
  img.aligncenter {
    display: block;
    margin: 0 auto;
  }
  img.alignright {
    display: block;
    margin: 0 0 0 auto;
  }
  img[class*="wp-image-"],
  img[class*="attachment-"] {
    height: auto;
    max-width: 100%;
  }
  hr{
    margin: 2em 0;
    border-color: #b2b2b2;
  }
  *{
    & + p{
      margin-top: 1em;
    }
    & + ul,
    & + ol,
    & + .c-tbl_responsive {
      margin-top: 2em;
    }

    & + h1,
    & + h2,
    & + h3,
    & + h4,
    & + h5,
    & + h6 {
      margin-top: 1.5em;
    }
  }
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-weight: 500;
    line-height: 1.3;
    letter-spacing: .1em;
  }
  h2{
    position: relative;
    font-size: 1.75em;
    padding-left: 1.25em;
    &:before,
    &:after{
      position: absolute;
      content: "";
      left: 0;
      top: .15em;
      width: .25em;
      height: 1em;
      background: $main_color;
      transform: skewX(-15deg);
    }
    &:after{
      left: .5em;
    }
  }
  h3{
    position: relative;
    font-size: 1.5em;
    padding-left: 1.5em;
    &:before{
      position: absolute;
      content: "";
      left: 0;
      top: .65em;
      width: 1em;
      height: 2px;
      background: $main_color;
    }
  }
  h4{
    font-size: 1.25em;
    color: $main_color
  }

  a{
    color: $main_color;
    &:focus{
      opacity: .7;
    }
    @include mq(sm-){
      &:hover{
        opacity: .7;
      }
    }
  }

  ul,
  ol {
    padding-left: 2em;
    line-height: 1.5;
    > li:not(:first-child){
      margin-top: .6em;
    }
  }

  ul{
    > li{
      list-style-type: disc;
    }
  }
  ol{
    >li{
      list-style-type: decimal;
    }
  }

  table{
    tr{
      > th,
      > td{
        padding: 1em;
        border: 1px solid #ddd;
      }
      > th{
        background: #eee;
      }
    }
  }

  @include mq(xs){
    h2{
      font-size: 1.5em;
    }
    h3{
      font-size: 1.375em;
    }
    h4{
      font-size: 1.15em;
    }
  }

  @include mq(ss){
    h2{
      font-size: 1.375em;
    }
    h3{
      font-size: 1.25em;
    }
    h4{
      font-size: 1.125em;
    }
  }
  @include mq(sm){
    *{
      & + p,
      & + ul,
      & + ol,
      & + .c-tbl_responsive {
        margin-top: 1.2em;
      }
    }
    ul,
    ol {
      > li:not(:first-child){
        margin-top: .4em;
      }
    }
  }
}
/*-------------------------------
  WP > PAGINATION
-------------------------------*/
.wp-pagenavi{
  margin-top: $margin_md;
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 2;
  >*{
    display: inline-block;
    text-align: center;
    margin: 0 .5em;
    width: 2em;
    text-align: center;
    color:#fff;
  }
  .current{
    font-weight: 700;
    background: $main_color;
    border:solid 1px $main_color;
  }
  a{
    background: #fff;
    color: $main_color;
    border:solid 1px $main_color;
    @include mq(sm-){
      &:hover{
        background: $main_color;
        color: #fff;
      }
    }
  }
  .extend{
    color: $font_color;
  }
  @include mq(ss){
    .extend{
      display: none;
    }
  }
}
