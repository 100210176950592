@charset "utf-8";
/*============================================

  HOME

============================================*/
/*-------------------------------
  HOME > COMMON
-------------------------------*/
.p-home{
}

/*-------------------------------
  HOME > NEWS
-------------------------------*/
.p-home_news{
  background: $sub_color;
  padding: 10rem 0;
  line-height: 1;
  @include mq(xs){
    padding: 8rem 0;
  }
  .c-card_news{
    margin-bottom: 4rem;
  }
  &__foot{
    margin: auto;
  }
}

/*-------------------------------
  HOME > COMPANY
-------------------------------*/
.p-home_company{
  padding: 10rem 0;
  @include mq(xs){
    padding: 8rem 0;
  }
}

/*-------------------------------
  HOME > BUSINESS
-------------------------------*/
.p-home_business{
  background: $sub_color;
  padding: 10rem 0;
  @include mq(xs){
    padding: 8rem 0;
  }
}


/*-------------------------------
  HOME > LINK
-------------------------------*/
.p-home_link{
  padding: 10rem 0;
  display: flex;
  justify-content: space-between;
  @include mq(xs){
    padding: 8rem 0;
  }
}
