@charset "utf-8";
/*============================================

  CONTAINER

============================================*/
.c-inner{
  max-width: $base_width;
  margin-left: auto;
  margin-right: auto;
  width: 94%;
}

.c-logo{
  display: block;
  font-size: 1em;
  $amp: &;
  &__link{
    display: inline-flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  &__mark,
  &__txt{
    svg{
      width: 100%;
      height: 100%;
      fill: $main_color;
    }
  }
  &__mark{
    width: 6em;
    height: 2.906em;
    @include mq(sm-){
      margin-bottom: 1.7em;
    }
  }
  &__txt{
    width: 8.27em;
    height: 1.656em;
  }
}

.c-cat_list{
  width: 100%;
  justify-content: space-between;
  align-items: center;
  display: flex;
  border: 1px solid #E6E6E6;
  margin-bottom: 3rem;
  li{
    text-align: center;
    width: 100%;
    @include mq(xs-){
      &:nth-child(n+2){
        border-left:1px solid #E6E6E6;
      }
    }
    a{
      display: block;
      height: 100%;
      padding: 1em;
      @include mq(sm-){
        &:hover{
          background: $main_color;
          color: #fff;
        }
      }
    }
  }
  @include mq(xs){
    flex-wrap: wrap;
    border:none;
    li{
      width: 50%;
      &:nth-child(even){
        border-left:1px solid #E6E6E6;
      }
      border-bottom: 1px solid #E6E6E6;
      a{
        background: $main_color;
        color: #fff;
        padding: 0.5em;
      }
    }
  }
}


.c-privacy{
  &_wrap{
    margin-top: 2em;
  }
  &_ttl{
    font-size: 1.2em;
    margin-bottom: 0.5em;
  }
  &_box{
    &:nth-child(n+2){
      margin-top: 2em;
    }
  }
  &_link{
    margin-top: 1em;
    span{
      display: block;
      font-weight: 600;
      margin-bottom: 0.5em;
    }
    a{
      color: $main_color;
      text-decoration: underline;
      word-break: break-all;
      @include mq(sm-){
        &:hover{
          text-decoration: none;
        }
      }
    }
  }
}

/*============================================

  WRAP

============================================*/
.c-boxpd{
  padding: $margin_md*2 0;
  &_xl{
    padding: $margin_md*3 0;
  }
  &_ll{
    padding: $margin_ll 0;
  }
  &_lg{
    padding: $margin_lg 0;
  }
  &_md{
    padding: $margin_md 0;
  }
  &_sm{
    padding: $margin_sm 0;
  }
  &_ss{
    padding: $margin_ss 0;
  }
}
/*============================================

  IFRAME

============================================*/
.c-iframe{
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  iframe{
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }
}
/*============================================

  COLUMN

============================================*/
.c-flex{
  overflow: hidden;
  &__list{
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap;
    &.-col2{
      > *{
        width: calc(100% / 2);
      }
    }
    &.-col3{
      > *{
        width: calc(100% / 3);
      }
    }
    &.-col4{
      > *{
        width: calc(100% / 4);
      }
    }
  }
}
