@charset "utf-8";
/*============================================

  SDGS

============================================*/
/*-------------------------------
  BUSINESS > COMMON
-------------------------------*/

.p-sdgs_read{
  padding: 10rem 0;
  @include mq(xs){
    padding: 8rem 0;
  }
  &__head{
    font-size: 4rem;
    line-height: 1;
    margin-bottom: 2rem;
    font-weight: 500;
    @include mq(xs){
      font-size: 2.6rem;
      line-height: 1.3; 
    }
  }
  &__svg{
    width: 55%;
    height: 4.2em;
    margin: 0 auto 2rem;
    svg{
      width: 100%;
      height: 100%;
      display: block;
    }
    @include mq(xs){
      width: 90%;
      height: 2.2em;
    }
  }
}

.p-sdgs_cont{
  &__head{
    margin-bottom: 6rem;
    p{
      @include mq(xs-){
        text-align: center;
      }
    }
  }
  &__svg{
    width: 50%;
    height: 5em;
    margin: 0 auto 4rem;
    svg{
      width: 100%;
      height: 100%;
    }
    @include mq(xs){
      width: 90%;
      height: 3em;
      margin: 0 auto 3rem;
    }
  }
  &__body{
    border-top:1px solid $border_color;
    padding-top: 4rem;
  }
  &__block{
    @include mq(xs-){
      display: flex;
      justify-content: space-between;
    }
    border-bottom:1px solid $border_color;
    padding-bottom: 4rem;
    &:not(:last-child){
      margin-bottom: 4rem;
    }
    &:nth-child(even){
      flex-direction: row-reverse;
    }
  }
  &__img{
    @include mq(xs-){
      width: 41.6%;
    }
    @include mq(xs){
      margin-bottom: 1.5em;
    }
  }
  &__text{
    @include mq(xs-){
      width: 54.1%;
    }
  }
  &__sdgsttl{
    font-size: 3rem;
    font-weight: 500;
    margin-bottom: 0.3em;
    & span{
      font-size: 0.6em;
    }
    @include mq(xs){
      font-size: 2.4rem;
      margin-bottom: 0.5em;
    }
  }
  &__list{
    display: flex;
    align-items: stretch;
    margin-bottom: 0.3em;
    @include mq(xs){
      margin-bottom: 0.5em;
    }
    li{
      width: 18.4%;
      &:nth-child(n+2){
        margin-left: 1.3rem;
      }
      svg{
        display: block;
      }
      @include mq(xs){
        height: 5em;
      }
    }
    svg{
      width: 100%;
      height: 100%;
    }
  }
  &__point{
    li{
      line-height: 1.3;
      position: relative;
      padding-left: 1em;
      &:nth-child(n+2){
        margin-top: 0.5em;
      }
      &:before{
        content: "";
        background: $main_color;
        width: 10px;
        height: 10px;
        display: block;
        position: absolute;
        left: 0;
        top:0.3em;
        border-radius: 50%;
      }
    }
  }
}

.p-sdgs_target{
  padding: 10rem 0;
  @include mq(xs){
    padding: 8rem 0;
  }
  &__head{
    margin-bottom: 4rem;
    text-align: center;
  }
  &__ttl{
    font-size: 3rem;
    font-weight: 500;
    line-height: 1.3;
    @include mq(xs){
      font-size: 2.6rem;
    }
  }
  &__body{
    margin-bottom: 6rem;
    @include mq(xs-){
      text-align: center;
    }
    @include mq(xs){
      margin-bottom: 4rem;
    }
  }
  &__foot{

  }
}

