@charset "utf-8";
/*============================================

  NEWS

============================================*/
/*-------------------------------
  NEWS > COMMON
-------------------------------*/

.p-news{
  &__archive{
    padding: 10rem 0;
    @include mq(xs){
      padding: 8rem 0;
    }
  }
  &_single{
    &__wrap{
      padding:10rem 0;
      @include mq(xs){
        padding:8rem 0;
      }
    }
    &__head{
      border-bottom:1px solid #ddd;
      padding-bottom:2em;
      margin-bottom: 2em;
      time{
        display: block;
        line-height: 1;
        margin-bottom: 1em;
      }
      h1{
        font-size: 2.4rem;
        line-height: 1.3;
        color: $main_color;
      }
      @include mq(sm){
        h1{
          font-size: 2.2rem;
        }
      }
      @include mq(xs){
        h1{
          font-size: 1.8rem;
          line-height: 1.4;
        }
        padding-bottom:1em;
        margin-bottom: 1em;
      }
    }
    &__body{
      border-bottom:1px solid #ddd;
      padding-bottom:3em;
      margin-bottom: 3em;
      .js-editor{
        width: 100%;
        padding: 0 2em;
        margin: auto;
      }
      @include mq(xs){
        padding-bottom:2em;
        margin-bottom: 2em;
      }
    }
  }
}