@charset "utf-8";
/*============================================

  SITEMAP

============================================*/
/*-------------------------------
  SITEMAP > COMMON
-------------------------------*/
.p-site_cont{
  padding: 10rem 0;
  @include mq(xs){
    padding: 8rem 0;
  }
  &__list{
    li{
      border-bottom: 1px solid $border_color;
      line-height: 1;
    }
    a{
      display: flex;
      align-items: baseline;
      font-size: 1.6rem;
      padding: 1em;
      @include mq(sm-){
        &:hover{
          opacity: 0.6;
        }
      }
    }
    span{
      color: $main_color;
      font-family: $font_en;
      font-size: 1.3em;
      font-weight: 500;
      margin-right: 0.5em;
    }
    p{

    }
  }
}
