@charset "utf-8";
/*============================================

  BUSINESS

============================================*/
/*-------------------------------
  BUSINESS > COMMON
-------------------------------*/

.p-about_mssage{
  padding: 10rem 0;
  margin-bottom: 10rem;
  position: relative;
  @include mq(xs-){
    &:before{
      content: "";
      background: $main_color;
      width: 60vw;
      height: 100%;
      display: block;
      position: absolute;
      right: 0;
      top:0;
      z-index: 1;
    }
  }
  @include mq(xs){
    background: $main_color;
    padding: 8rem 0;
    margin-bottom: 8rem;
  }
  &__inner{
    @include mq(xs-){
      display: flex;
      justify-content: space-between;
      align-items: stretch;
    }
  }
  &__img{
    @include mq(xs-){
      position: relative;
      width: 50%;
      z-index: 5;
    }
    figure{
      @include mq(xs-){
        position: absolute;
        right: 0;
        top:50%;
        width: 50vw;
        height: 50rem;
        transform: translateY(-50%);
      }
    }
    img{
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
    @include mq(sm){
      width: 45%;
    }
    @include mq(xs){
      width: 100%;
      margin-bottom: 1.5em;
    }
  }
  &__text{
    width: 43.3%;
    position: relative;
    z-index: 3;
    color: #fff;
    @include mq(sm){
      width: 50%;
    }
    @include mq(xs){
      width: 100%;
    }
  }
  &__ttl{
    font-weight: normal;
    display: flex;
    align-items: baseline;
    margin-bottom: 1em;
    em{
      font-family: $font_en;
      font-size: 2.4rem;
      font-style: normal;
      &:after{
        content: "/";
        margin-right: 0.5em;
        margin-left: 0.5em;
      }
    }
  }
  p{
    &:nth-child(n+2){
      margin-top: 1em;
    }
  }
}

.p-about_philosophy{
  background: $sub_color;
  padding: 10rem 0;
  @include mq(xs){
    padding: 8rem 0;
  }
  &__text{
    font-size: 2.6rem;
    text-align: center;
    line-height: 2.1;
    @include mq(sm){
      font-size: 2.2rem;
    }
    @include mq(xs){
      font-size: 1.8rem;
    }
  }
}

.p-about_company{
  padding: 10rem 0;
  @include mq(xs){
    padding: 8rem 0;
  }
}

/*-------------------------------
  BUSINESS > construction
-------------------------------*/
.p-construction_mssage{
  padding: 10rem 0;
  @include mq(xs){
    padding: 8rem 0;
  }
}