@charset "utf-8";
/*============================================

  CONTACT

============================================*/
/*-------------------------------
  CONTACT > COMMON
-------------------------------*/
.p-contact{
  &__block{
    padding: 10rem 0;
    @include mq(xs){
      padding: 8rem 0;
    }
  }
}

/*-------------------------------
  CONTACT > THANKS
-------------------------------*/
.p-thanks{
  &__txt{
    margin-top: $margin_md;
    text-align: center;
    line-height: 2;
  }
  &__btn{
    text-align: center;
    margin-top: $margin_md;
  }
}
