@charset "utf-8";
/*============================================

  OTHER

============================================*/
/*-------------------------------
  HEALTH > COMMON
-------------------------------*/

.p-health_attempt{
  padding: 0 0 10rem;
  @include mq(xs){
    padding: 0 0 8rem;
  }
  .c-card_cont{
    &.-clm3{
      margin: 10rem 0 0 ;
      @include mq(xs){
        margin: 8rem 0 0;
      }
    }
  }
}

