@charset "utf-8";
/*============================================

  LIST PRIMARY

============================================*/
.c-list_pr{
  li{
    line-height: 1.5;
    padding: 1em 0 0;
    @include mq(xs-){
      display: flex;
      align-items: stretch;
    }
    em{
      display: block;
      border-bottom: 2px solid $main_color;
      font-style: normal;
      text-align: center;
      padding-bottom: 1em;
      @include mq(xs-){
        width: 25%;
      }
      @include mq(xs){
        padding-bottom: 0.5em;
      }
    }
    p{
      display: block;
      border-bottom: 2px solid $border_color;
      padding: 0 1.5em;
      padding-bottom: 1em;
      &.map{
        position: relative;
        svg{
          fill:$main_color;
          width: 1.2em;
          height: 1.2em;
          margin-left: 0.3em;
        }
      }
      @include mq(sm-){
        a{
          display: inline-block;
          &:hover{
            transform: translateY(-0.5em);
          }
        }
      }
      @include mq(xs-){
        width: 75%;
      }
      @include mq(xs){
        padding: 0.6em 0 1em;
      }
    }
  }
}
/*============================================

  LIST NUMBER

============================================*/
.c-list_num{
}
/*============================================

  LIST HISTORY

============================================*/
.c-list_histry{
  li{
    line-height: 1.5;
    padding: 1em 0 0;
    border-bottom: 1px solid $border_color;
    @include mq(xs-){
      display: flex;
      align-items: stretch;
      justify-content: space-between; 
    }
  }
  &__date{
    position: relative;
    margin-bottom: 1em;
    width: 19%;
    @include mq(xs-){
      margin-right: 4.8rem;
    }
    &:before{
      content: "";
      width: 1.4rem;
      height: 1.4rem;
      border: 3px solid #2b77e0;
      display: inline-block;
      border-radius: 100%;
      margin-right: 0.5em;
    }
    @include mq(xs-){
      &:after{
        content: "";
        background: #2b77e0;
        width: 4.8rem;
        height: 1px;
        display: block;
        position: absolute;
        right: -4.8rem;
        top: 50%;
      }
    }
    @include mq(sm){
      width: 23%;
    }
    @include mq(xs){
      width: 100%;
      margin-bottom: 0.5em;
    }
  }
  &__text{
    width: 73%;
    margin-bottom: 1em;
    @include mq(sm){
      width: 70%;
    }
    @include mq(xs){
      width: 100%;
    }
  }
}
