@charset "utf-8";
/*============================================

  CONTENT

============================================*/
.l-main{
}

.l-loading{
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
  &__img{
    display: none;
  }
}

.l-page{
  &.-under{
    padding-top: 122px;
    @include mq(xs){
      padding-top: 70px;
    }
  }
}