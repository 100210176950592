@charset "utf-8";
/*============================================

  MODAL WINDOW

============================================*/
.c-modal{
  position: fixed;
  display: none;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  z-index: 100;
  &__bg{
    position: absolute;
    background: rgba(0,0,0,.7);
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
  }
  &__container{
    position: absolute;
    max-width: $base_width;
    width: 94%;
    max-height: 94%;
    top: 50%;
    left: 50%;
    transform:translate(-50%,-50%);
    z-index: 2;
  }
  &__inner{
    background: #fff;
    padding: $margin_sm;
    &::-webkit-scrollbar {
      width: .6rem;
    }
    &::-webkit-scrollbar-track {
      background: #eee;
    }
    &::-webkit-scrollbar-thumb {
      background: $accent_color;
    }
  }
  &__video{
    position: relative;
    width: 100%;
    padding-top: 56.25%;
    iframe{
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
    }
  }
  &__close{
    position: absolute;
    right: 0;
    top: 0;
    width: $margin_sm;
    height: $margin_sm;
    background: rgba(0,0,0,.5);
    &:before,
    &:after{
      position: absolute;
      content: "";
      left: 25%;
      top: calc(50% - 1px);
      width: 50%;
      height: 2px;
      background: #fff;
    }
    &:before{
      transform: rotate(45deg);
    }
    &:after{
      transform: rotate(-45deg);
    }
  }
}
