@charset "utf-8";
$breakpoint: (
  '': '',
  'lg': 'md-lg',
  'md': 'sm-md',
  'sm': 'xs-sm',
  'xs': 'ss-xs',
  'ss': 'ss',
  'pc': 'sm-',
  'tb': 'sm',
  'sp': 'xs',
);
/*============================================

  BACKGROUND

============================================*/
.u-bg_fcolor{
  background: $font_color;
}
.u-bg_main{
  background: $main_color;
}
.u-bg_sub{
  background: $sub_color;
}
.u-bg_accent{
  background: $accent_color;
}
/*============================================

  DISPLAY

============================================*/
@each $class, $bp in $breakpoint{
  @if $bp == '' {
    .u-block{
      display: block;
    }
    .u-iblock{
      display: inline-block;
    }
    .u-inline{
      display: inline;
    }
  } @else {
    @include mq($bp){
      .u-block_#{$class}{
        display: block;
      }
      .u-iblock_#{$class}{
        display: inline-block;
      }
      .u-inline_#{$class}{
        display: inline;
      }
    }
  }
}
/*============================================

  PADDING / MARGIN

============================================*/
$property : (
  'pd':'padding',
  'pdt':'padding-top',
  'pdb':'padding-bottom',
  'pdl':'padding-left',
  'pdr':'padding-right',
  'mg':'margin',
  'mgt':'margin-top',
  'mgb':'margin-bottom',
  'mgl':'margin-left',
  'mgr':'margin-right',
);
$limit : 10000;
$step : 125;
$val : 0;
@while ($val <= $limit){
  $class_val: '';
  @if $val == 0{
    $class_val: 0;
  } @else if $val < 1000 {
    $class_val: 0#{$val};
  } @else{
    $class_val: #{$val};
  }
  @each $class, $prop in $property{
    @each $bp, $break in $breakpoint{
      @if $bp == '' {
        .u-#{$class}#{$class_val}{
          #{$prop}: #{$val / 1000}em;
        }
      }
      @else {
        @include mq($break){
          .u-#{$class}#{$class_val}_#{$bp}{
            #{$prop}: #{$val / 1000}em;
          }
        }
      }
    }
  }
  $val : $val + $step;
}