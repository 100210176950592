@charset "utf-8";
/*============================================

  TITLE

============================================*/
/*-------------------------------
  TITLE > VERTICAL LINE
-------------------------------*/
.c-ttl_vl{
}

/*-------------------------------
  TITLE > SUPPLEMENT
-------------------------------*/
.c-ttl_sup{
  text-align: center;
  font-size: 3rem;
  font-weight: 500;
  line-height: 1;
  margin-bottom: 4rem;
  p{
    margin-bottom: 0.3em;
  }
  em{
    font-style: normal;
    font-size: 0.8em;
    font-family: $font_en;
  }
  span{
    display: block;
    margin-top: 1.4em;
    font-size: 1.6rem;
  }
  @include mq(sm){
    font-size: 2.6rem;
  }
  @include mq(xs){
    font-size: 2.4rem;
    margin-bottom: 2.6rem;
    span{
      font-size: 1.4rem;
    }
  }
}
/*-------------------------------
  TITLE > LINE
-------------------------------*/
.c-ttl_line{
}
/*-------------------------------
  TITLE > ICO
-------------------------------*/
.c-ttl_ico{
}
