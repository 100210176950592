@charset "utf-8";
/*============================================

  PRIVACY POLICY

============================================*/
/*-------------------------------
  PRIVACY > COMMON
-------------------------------*/
.p-privacy_cont{
  padding: 10rem 0;
  @include mq(xs){
    padding: 8rem 0;
  }
}
