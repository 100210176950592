@charset "utf-8";
/*============================================

  GLOBAL NAVIGATION

============================================*/
.p-gnavi{
  $amp: &;
  width: 100%;
  &__inner{
    width: 100%;
    line-height: 1;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  @include mq(sm-){
    &__list{
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: calc((100% - 30rem) / 2);
    }
  }
  @include mq(sm-){
    position: absolute;
    left: 0;
    top: 50%;
    width: 100%;
    transform: translateY(-50%);
    z-index: 1;
    &__list{
      li{
        a{
          position: relative;
          display: block;
          transition:0s;
          span{
            transition:inherit;
          }
          &:before{
            content: "";
            background: #fff;
            width: 0%;
            height: 2px;
            display: block;
            position: absolute;
            left: 0;
            bottom: -0.4em;
            transition-duration: 0.3s;
          }
          &:hover{
            &:before{
              width: 100%;
            }
          }
        }
      }
    }
  }
  @include mq(sm){
    background: $main_color;
    font-size: 1.6rem;
    &__inner{
      display: block;
      padding: $margin_ll $margin_sm $margin_md;
    }
    &__list{
      li{
        border-bottom:1px solid #fff
      }
      a{
        display: block;
        padding: 1em;
        color: #fff;
      }
    }
    @include mq(xs-){
      width: 40vw;
    }
  }
}
