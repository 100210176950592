@charset "utf-8";
/*============================================

  RECRUIT

============================================*/
/*-------------------------------
  BUSINESS > COMMON
-------------------------------*/

.p-recruit_reason{
  padding: 0 0 10rem;
  @include mq(xs){
    padding: 0 0 8rem;
  }
  &__cont{

  }
  &__block{
    @include mq(xs-){
      display: flex;
      justify-content: space-between;
      &:nth-child(even){
        flex-direction: row-reverse;
      }
    }
    padding-bottom: 4rem;
    &:not(:last-child){
      margin-bottom: 4rem;
      border-bottom: 1px solid $border_color;
    }
  }
  &__img{
    @include mq(xs-){
      width: 41.6%;
    }
    @include mq(xs){
      margin-bottom: 1.5em;
    }
  }
  &__text{
    @include mq(xs-){
      width: 54.1%;
    }
  }
  &__ttl{
    font-size: 3rem;
    margin-bottom: 0.5em;
    span{
      display: flex;
      align-items: center;
      line-height: 1;
      font-size: 0.53em;
      margin-bottom: 0.6em;
      color:#606060;
      &:before{
        content: "";
        background: $main_color;
        width: 15px;
        height: 2px;
        display: block;
        margin-right: 0.5em;
      }
    }
    p{
      line-height: 1.3;
    }
    @include mq(xs){
      font-size: 2.4rem;
    }
  }
}

.p-recruit_interview{
  padding: 10rem 0;
  background: url('/assets/img/recruit/bg_interview.jpg') no-repeat;
  background-size: cover;
  color: #fff;
  @include mq(xs){
    padding: 8rem 0;
  }
  &__head{
    >p{
      text-align: center;
      margin-bottom: 4rem;
      font-size: 1.25em;
      @include mq(xs){
        font-size: 1.6rem;
      }
    }
  }
  &__body{
    p{
      &:nth-child(n+2){
        margin-top: 1em;
      }
    }
  }
}

.p-recruit_description{
  padding: 10rem 0;
  @include mq(xs){
    padding: 8rem 0;
  }
  &__ttl{
    font-size: 1.8rem;
    text-align: center;
    margin-bottom: 0.5em;
    &:after{
      content: "";
      width: 30px;
      height: 3px;
      background: $main_color;
      display: inline-block;
      text-align: center;
    }
  }
}