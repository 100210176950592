@charset "utf-8";
/*============================================

  BUSINESS

============================================*/
/*-------------------------------
  BUSINESS > COMMON
-------------------------------*/

.p-business{
  &__block{
    padding: 10rem 0;
    @include mq(xs){
      padding: 8rem 0;
    }
  }

}

/*-------------------------------
  BUSINESS > FLOW
-------------------------------*/
.p-architecture_flow{
  padding: 10rem 0;
  @include mq(xs){
    padding: 8rem 0;
  }
}


/*-------------------------------
  BUSINESS > REFORM
-------------------------------*/
.p-architecture_reform{
  padding: 10rem 0;
  @include mq(xs){
    padding: 8rem 0;
  }
  &__card{
    @include mq(xs-){
      display: flex;
      justify-content: space-between;
      align-items: stretch;
    }
  }
  li{
    padding: 3.5rem;
    border: 1px solid $border_color;
    position: relative;
    @include mq(xs-){
      width: 29.1%;
    }
    @include mq(xs){
      &:nth-child(n+2){
        margin-top: 2em;
      }
    }
  }
  &__img{
    position: relative;
    width: 10rem;
    padding-top: 10rem;
    background: $main_color;
    border-radius: 50%;
    margin: 0 auto 1em;
    svg{
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50% ,-50%);
      height: 5.4rem;
      width: 100%;
      fill:#fff
    }
  }
  &__text{
    em{
      font-size: 2.2rem;
      line-height: 1.4;
      font-style: normal;
      margin-bottom: 0.4em;
      text-align: center;
      display: block;
    }
    p{
      line-height: 1.5;
    }
  }
}



/*-------------------------------
  BUSINESS > COMMUNICATION　EQUIPMENT
-------------------------------*/

.p-equipment_service{
  padding: 10rem 0;
  @include mq(xs){
    padding: 8rem 0;
  }
}

/*-------------------------------
  BUSINESS > antibacterial
-------------------------------*/

.p-antibacterial_service{
  padding: 10rem 0;
  @include mq(xs){
    padding: 8rem 0;
  }
}

.p-antibacterial_pctlst{
  padding: 0 0 10rem;
  @include mq(xs){
    padding: 0 0 8rem;
  }
  &__text{
    text-align: center;
    margin-bottom: 2.3em;
    em{
      font-style: normal;
      font-size: 2.6rem;
      margin-bottom: 0.8em;
      display: block;
    }
    @include mq(xs){
      em{
        font-size: 1.8rem;
      }
      p{
        text-align: left;
      }
    }
  } 
  &__img{
    border: 1px solid $border_color;
    padding: 3em 4em;
    @include mq(xs){
      padding: 0.5em;
    }
  }
}

.p-antibacterial_solution{
  &__img{
    max-width: 44.7rem;
    margin: 0 auto 2.3em;
  }
  &__text{
    margin-bottom: 4em;
    @include mq(xs-){
      text-align: center;
      line-height: 2.2;
    }
  }
  &__foot{
    border: 1px solid $border_color;
    padding: 3em 4em;
    @include mq(xs){
      padding: 1em;
    }
  }
}

.p-antibacterial_evidence{
  padding: 10rem 0;
  @include mq(xs){
    padding: 10rem 0;
  }
  &__img{
    max-width: 70rem;
    margin: auto;
  }
}

.p-antibacterial_site{
  padding: 0 0 10rem;
  @include mq(xs){
    padding: 0 0 8rem;
  }
}

.p-communication_service{
  padding: 10rem 0;
  @include mq(xs){
    padding: 8rem 0;
  }
}