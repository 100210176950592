/*--------------------------------------------
  Print
--------------------------------------------*/
@media print {
	@page {
		margin: 8mm;
		margin: 10mm 8mm 10mm 8mm;
	}
	* {
		-webkit-print-color-adjust: exact !important;
		background-attachment: initial !important;
		overflow-y: visible !important;
	}
	html {
		overflow: visible !important;
		zoom: 0.7 !important;
	}
	body {
		min-width: 1240px !important;
		padding-top: 0px !important;
		padding-bottom: 0px !important;
	}
	*,
	*:before,
	*:after {
		color: #000 !important;
		text-shadow: none !important;
		background: transparent !important;
		-webkit-box-shadow: none !important;
		box-shadow: none !important;
	}
	a,
	a:visited {
		text-decoration: underline;
	}
	a[href]:after {
		content: " (" attr(href) ")";
	}
	abbr[title]:after {
		content: " (" attr(title) ")";
	}
	a[href^="#"]:after,
	a[href^="javascript:"]:after {
		content: "";
	}
	pre,
	blockquote {
		border: 1px solid #999;
		page-break-inside: avoid;
	}
	thead {
		display: table-header-group;
	}
	tr,
	img {
		page-break-inside: avoid;
	}
	img {
		max-width: 100% !important;
	}
	p,
	h2,
	h3 {
		orphans: 3;
		widows: 3;
	}
	h2,
	h3 {
		page-break-after: avoid;
	}
}


.visible-print {
	display: none !important;
}
@media print {
	.visible-print {
		display: block !important;
	}
	table.visible-print {
		display: table !important;
	}
	tr.visible-print {
		display: table-row !important;
	}
	th.visible-print,
	td.visible-print {
		display: table-cell !important;
	}
}

.visible-print-i {
	display: none !important;
}
@media print {
	.visible-print-i {
		display: inline !important;
	}
}

.visible-print-ib {
	display: none !important;
}
@media print {
	.visible-print-ib {
		display: inline-block !important;
	}
}

@media print {
	.hidden-print {
		display: none !important;
	}
}


@media print {
	.visible-ss,
	.visible-xs,
	.visible-sm,
	.visible-md {
		display: none !important;
	}
	.visible-lg {
		display: block !important;
	}
	.visible-ss-i,
	.visible-ss-ib,
	.visible-xs-i,
	.visible-xs-ib,
	.visible-sm-i,
	.visible-sm-ib,
	.visible-md-i,
	.visible-md-ib {
		display: none !important;
	}
	.visible-lg-i {
		display: inline !important;
	}
	.visible-lg-ib {
		display: inline-block !important;
	}
	.hidden-ss,
	.hidden-xs,
	.hidden-sm,
	.hidden-md {
		display: block !important;
	}
	.hidden-lg {
		display: none !important;
	}

}
