@charset "utf-8";
/*============================================

  CARD

============================================*/
/*-------------------------------
  CARD > NEWS
-------------------------------*/
.c-card_news{
  $amp: &;
  li{
    border-bottom:1px solid $font_color;
    position: relative;
    &:first-child{
      border-top:1px solid $font_color;
    }
    &:before{
      content: "";
      background: #d8d8d8;
      width: 0%;
      height: 100%;
      display: block;
      position: absolute;
      left: 0;
      top:0;
      transition-duration: 0.5s;
    }
    &:hover{
      &:before{
        width: 100%;
      }
    }
    a{
      display: flex;
      align-items: baseline;
      padding: 1.5em;
      position: relative;
      @include mq(xs-){
        justify-content: space-between;
      }
      @include mq(xs){
        flex-wrap: wrap;
        align-items: center;
      }
      time{
        font-size: 1.8rem;
        font-family: $font_en;
        width: 6.7%;
        @include mq(sm){
          width: 5em;
        }
      }
      span{
        display: block;
        font-size: 1.2rem;
        color: #fff;
        background: $main_color;
        line-height: 1;
        padding: 3px 10px;
        min-width: 7em;
        text-align: center;
        @include mq(sm){
          margin-right: 1em;
        }
      }
      p{
        width: 83.5%;
        @include mq(xs){
          width: 100%;
          margin-top: 0.5em;
        }
      }
    }
  }
}

/*-------------------------------
  CARD > BUSINESS
-------------------------------*/
.c-card_business{
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  $amp: &;
  li{
    @include mq(xs-){
      width: 50%;
      &:nth-child(odd){
        #{$amp}__text{
          padding-top: 4rem;
          position: relative;
          transition-duration: 0.3s;
          z-index: 2;
          &:before{
            content: "";
            width: 1px;
            height: 4rem;
            background: $font_color;
            display: block;
            position: absolute;
            left: 50%;
            top:-2rem;
          }
        }
        a{
          &:hover{
            @include mq(sm-){
              #{$amp}__text{
                transform: translateY(1rem);
              }
            }
          }
        }
      }
      &:nth-child(even){
        margin-top: 8rem;
        #{$amp}__text{
          padding-bottom: 4rem;
          position: relative;
          transition-duration: 0.3s;
          z-index: 2;
          &:before{
            content: "";
            width: 1px;
            height: 4rem;
            background: $font_color;
            display: block;
            position: absolute;
            left: 50%;
            bottom:-2rem;
          }
        }
        a{
          @include mq(sm-){
            &:hover{
              #{$amp}__text{
                transform: translateY(-1rem);
              }
            }
          }
        }
      }
      a{
        @include mq(sm-){
          &:hover{
            #{$amp}__figure img{
              transform: scale(1.2);
            }
          }
        }
      }
    }
    @include mq(xs){
      &:nth-child(n+2){
        margin-top: 1em;
      }
    }
  }
  &__figure{
     overflow:hidden;
     position: relative;
     z-index: 1;
     img{
      transition-duration: 0.3s;
     }
  }
  &__text{
    font-size: 2.4rem;
    line-height: 1;
    text-align: center;
    em{
      font-style: normal;
      font-size: 0.6em;
      font-family: $font_en;
    }
  }
  @include mq(xs){
    a{
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      height: 100%;
    }
    &__figure{
      width: 60%;
      height: 100%;th: 60%;
      img{
        object-fit: cover;
        width: 100%;
        height: 100%;
      }
    }
    li{
      &:nth-child(even){
        #{$amp}__text{
          &:before{
            content: "";
            background: $font_color;
            width: 5rem;
            height: 1px;
            display: block;
            position: absolute;
            right: 0;
            top:50%;
            transform: translateX(80%);
            z-index: 5;
          }
        }
      }
      &:nth-child(odd){
        #{$amp}__text{
          &:before{
            content: "";
            background: $font_color;
            width: 5rem;
            height: 1px;
            display: block;
            position: absolute;
            left: 0;
            top:50%;
            transform: translateX(-80%);
            z-index: 5;
          }
        }
      }
    }
    &__text{
      width: 35%;
      font-size: 2rem;
      padding: 2em 0;
      position: relative;
    }
  }
}

/*-------------------------------
  CARD > LINK
-------------------------------*/
.c-card_link{
  @include mq(xs-){
    display: flex;
    justify-content: space-between;
    align-items: stretch;
  }
  font-size: 1rem;
  $amp: &;
  @include mq(xs){
    font-size: 0.8rem;
  }
  a{
    @include mq(sm-){
      &:hover{
        #{$amp}__figure{
          img{
            transform: scale(1.2);
          }
        }
      }
    }
  }
  li{
    @include mq(xs-){
      width: 31.5%;
    }
    position: relative;
    @include mq(xs){
      &:nth-child(n+2){
        margin-top: 1em;
      }
    }
  }
  a{
    display: block;
    width: 100%;
    height: 100%;
    position: relative;
  }
  &__figure{
    overflow: hidden;
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top:0;
    z-index: 1;
    &:before{
      content: "";
      width: 96%;
      height: 90%;
      border: 1px solid #fff;
      display: block;
      position: absolute;
      left: 50%;
      top:50%;
      transform: translate(-50%,-50%);
      z-index: 2;
    }
    img{
      transition-duration: 0.3s;
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  }
  &__body{
    text-align: center;
    position: relative;
    z-index: 5;
    padding: 4.7em 0;
  }
  &__icon{
    svg{
      height: 3.3em;
      width: 100%;
      fill: #fff;
    }
  }
  &__ttl{
    color: #fff;
    line-height: 1;
    font-weight: 500;
    p{
      font-size: 2.2em;
      font-family: "Bellefair",serif;
      margin-bottom: 0.3em;
    }
    em{
      font-style: normal;
      font-size: 1.4em;
    }
  }
}

/*-------------------------------
  CARD > CONTENT
-------------------------------*/
.c-card_cont{
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  justify-content: space-between;
  >li{
    width: 45%;
    &:nth-child(n+3){
      margin-top: 6rem;
    }
    @include mq(xs){
      width: 100%;
      &:nth-child(n+2){
        margin-top: 3em;
      }
    }
  }
  &__img{
    margin-bottom: 1em;
  }
  &__text{
    em{
      font-style: normal;
      font-size: 2.2rem;
      margin-bottom: 0.5em;
      text-align: center;
      display: block;
    }
    @include mq(xs){
      em{
        font-size: 2rem;
      }
    }
  }
  &.-clm3{
    li{
      @include mq(xs-){
        width: 29.1%;
        margin-top: 0;
        &:nth-child(n+4){
          margin-top: 6rem;
        }
      }
    }
  }
  &__list{
    border: 2px solid $main_color;
    font-size: 1.4rem;
    padding: 2rem;
    margin-top: 1.5em;
    li{
      position: relative;
      padding-left: 1.3em;
      line-height: 1.3;
      &:before{
        content: "";
        background: $main_color;
        width: 8px;
        height: 8px;
        display: block;
        position: absolute;
        left: 0;
        top:0.4em;
        border-radius: 50%;
      }
      &:nth-child(n+2){
        margin-top: 0.5em;
        @include mq(xs){
          margin-top: 0.8em;
        }
      }
    }
  }
}