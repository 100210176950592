@charset "utf-8";
/*============================================

  HEADER

============================================*/
.l-header{
  padding: 3rem;
  transition-property: padding, background;
  transition-duration: 0.3s;
  position: fixed;
  left: 0;
  top:0;
  z-index: 100;
  width: 100%;
  background: #fff;
  &.-top{
    background: none;
    color: #fff;
    .c-logo__txt,
    .c-logo__mark{
      svg{
        fill: #fff;
      }
    }
  }
  &.js-fixed{
    background: #fff;
    color: $font_color;
    .c-logo__txt,
    .c-logo__mark{
      svg{
        fill: $main_color;
      }
    }
    @include mq(sm-){
      padding: 0.8em 3rem;
      .c-logo__mark{
        margin-bottom: 0.8em;
      }
    }
  }
  &__logo{
    position: relative;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    line-height:1;
    font-size: 1rem;
    z-index: 2;
    @include mq(sm-){
      a{
        &:hover{
          opacity: 0.4;
        }
      }
    }
  }
  &__inner{
    position: relative;
    z-index: 100;
    @include mq(sm-){
      text-align: center;
    }
    @include mq(sm){
      display: flex;
      justify-content: space-between;
    }
  }
  &__hamburger{
    width: 6rem;
    height: 6rem;
    background: $main_color;
    span{
      position: absolute;
      width: 60%;
      height: 2px;
      background: #fff;
      left: 20%;
      top: calc(50% - 1px);
      transition:all $duration;
      &:before,
      &:after{
        position: absolute;
        content:"";
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        transform-origin: center center;
        background: #fff;
        transition:inherit;
      }
      &:before{
        transform:translateY(-.75em);
      }
      &:after{
        transform:translateY(.75em);
      }
    }
    .slideout-open &{
      span{
        background: transparent;
        &:before{
          transform:rotate(45deg);
        }
        &:after{
          transform:rotate(-45deg);
        }
      }
    }
  }
  @include mq(sm-){
  }
  @include mq(sm){
    align-items: center;
    padding: $margin_ss 0;
  }
  @include mq(xs){
    padding: 0.6em 0;
  }
}
.slideout-open .slideout-panel {
  z-index: 99;
}