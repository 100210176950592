
@-ms-viewport {
  width: device-width;
}

[role="button"] {
  cursor: pointer;
}

.hidden {
  display: none !important;
}
.show {
  display: block !important;
}

.visible-ss,
.visible-xs,
.visible-sm,
.visible-md,
.visible-lg {
  display: none !important;
}
.visible-ss-i,
.visible-xs-i,
.visible-sm-i,
.visible-md-i,
.visible-lg-i {
  display: none !important;
}
.visible-ss-ib,
.visible-xs-ib,
.visible-sm-ib,
.visible-md-ib,
.visible-lg-ib {
  display: none !important;
}
@include mq(ss) {
  .visible-ss {
    display: block !important;
  }
  table.visible-ss {
    display: table !important;
  }
  tr.visible-ss {
    display: table-row !important;
  }
  th.visible-ss,
  td.visible-ss {
    display: table-cell !important;
  }
  .visible-ss-i {
    display: inline !important;
  }
  .visible-ss-ib {
    display: inline-block !important;
  }
}

@include mq(ss-xs) {
  .visible-xs {
    display: block !important;
  }
  table.visible-xs {
    display: table !important;
  }
  tr.visible-xs {
    display: table-row !important;
  }
  th.visible-xs,
  td.visible-xs {
    display: table-cell !important;
  }
  .visible-xs-i {
    display: inline !important;
  }
  .visible-xs-ib {
    display: inline-block !important;
  }
}

@include mq(xs-sm) {
  .visible-sm {
    display: block !important;
  }
  table.visible-sm {
    display: table !important;
  }
  tr.visible-sm {
    display: table-row !important;
  }
  th.visible-sm,
  td.visible-sm {
    display: table-cell !important;
  }
  .visible-sm-i {
    display: inline !important;
  }
  .visible-sm-ib {
    display: inline-block !important;
  }
}

@include mq(sm-md) {
  .visible-md {
    display: block !important;
  }
  table.visible-md {
    display: table !important;
  }
  tr.visible-md {
    display: table-row !important;
  }
  th.visible-md,
  td.visible-md {
    display: table-cell !important;
  }
  .visible-md-i {
    display: inline !important;
  }
  .visible-md-ib {
    display: inline-block !important;
  }
}

@include mq(md-) {
  .visible-lg {
    display: block !important;
  }
  table.visible-lg {
    display: table !important;
  }
  tr.visible-lg {
    display: table-row !important;
  }
  th.visible-lg,
  td.visible-lg {
    display: table-cell !important;
  }
  .visible-lg-i {
    display: inline !important;
  }
  .visible-lg-ib {
    display: inline-block !important;
  }
}

@include mq(ss) {
  .hidden-ss {
    display: none !important;
  }
}
@include mq(ss-xs) {
  .hidden-xs {
    display: none !important;
  }
}
@include mq(xs-sm) {
  .hidden-sm {
    display: none !important;
  }
}
@include mq(sm-md) {
  .hidden-md {
    display: none !important;
  }
}
@include mq(md-) {
  .hidden-lg {
    display: none !important;
  }
}


@include mq(xs-) {
  .visible-sp {
    display: none !important;
  }
}
@include mq(sm-) {
  .visible-tb {
    display: none !important;
  }
}
@include mq(sm) { 
  .visible-pc {
    display: none !important;
  }
}

@include mq(xs) {
  .hidden-sp {
    display: none !important;
  }
}
@include mq(sm) {
  .hidden-tb {
    display: none !important;
  }
}
@include mq(sm-) {
  .hidden-pc {
    display: none !important;
  }
}