/*--------------------------------------------
  MIXIN 等
--------------------------------------------*/

// media query
@mixin mq($breakpoint: sm) {
  @media #{map-get($breakpoints, $breakpoint)} {
    @content;
  }
}
