@charset "utf-8";
/*============================================

  BUTTON WRAP

============================================*/
.c-btnwrap{
  display: flex;
  align-items: stretch;
  justify-content: center;
}
/*============================================

  BUTTON PLAIN

============================================*/
.c-btn_pl{
  background: $main_color;
  color: #fff;
  border: 2px solid $main_color;
  width: 30rem;
  padding: 1.8rem 1em;
  text-align: center;
  display: block;
  margin: auto;
  @include mq(sm-){
    &:hover{
      background: #fff;
      color: $main_color;
    }
  }
  @include mq(xs){
    font-size: 1.4rem;
  }
}
/*============================================

  BUTTON ROUND

============================================*/
.c-btn_rd{
  @include mq(sm-){
    &:hover{
    }
  }
}
