@charset "utf-8";
/*============================================

  IMAGE

============================================*/
/*-------------------------------
  CASE > OBJECT FIT
-------------------------------*/
.c-ofi{
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  &__img{
    position: absolute;
    left: 0;
    top: 0;
    max-width: none;
    max-height: none;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center center;
    font-family: 'object-fit: cover;object-position:center center;';
    &.-contain{
      object-fit: contain;
      font-family: 'object-fit: contain;object-position:center center;';
    }
  }
}
