@charset "utf-8";
/*============================================

  FOOTER

============================================*/
.l-footer{
  &_attempt{
    background: $sub_color;
    padding: 6rem 0;
    .c-ttl_sup{
      @include mq(xs-){
        text-align: left;
      }
      margin-bottom: 0;
    }
    &__inner{
      @include mq(xs-){
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
    }
    &__cont{
      display: flex;
      justify-content: space-between;
      align-items: stretch;
      width: 41%;
      li{
        text-align: center;
      }
      a{
        background: #fff;
        padding: 3rem;
        display: block;
        height: 100%;
        img{
          width: auto;
          height: 115px;
        }
        @include mq(sm-){
          &:hover{
            opacity: 0.6;
          }
        }
      }
      figure{
        margin-bottom: 1rem;
      }
      p{
        font-family: $font_sans;
        font-weight: 600;
      }
      @include mq(sm){
        width: 53%;
        a{
          img{
            height: 100px;
          }
        }
      }
      @include mq(xs){
        width: 100%;
        margin-top: 3rem;
        li{
          width: 48%;
        }
        p{
          font-size: 1.4rem;
        }
        a{
          padding: 1.5rem;
          img{
            height: 11rem;
          }
        }
      }
    }
  }
  &_cv{
    background: $main_color;
    color: #fff;
    padding: 6rem 0;
    @include mq(xs){
      padding: 4rem 0;
    }
    .c-ttl_sup{
      @include mq(xs-){
        text-align: left;
      }
      margin-bottom: 0;
      @include mq(xs){
        margin-bottom: 3rem;
      }
    }
    &__inner{
      @include mq(xs-){
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
    }
    &__cont{
      @include mq(xs-){
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
    }
    &__tel{
      @include mq(xs-){
        margin-right: 4rem;
      }
      p{
        font-size: 1.4rem;
      }
      span{
        display: block;
        font-size: 4rem;
        position: relative;
        display: flex;
        align-items: center;
        &:before{
          @include icon('freedial-fill_01');
          color: #fff;
          z-index: 999;
          position: relative;
          margin-right: 0.3em;
          font-size: 1.25em;
        }
      }
      @include mq(xs){
        margin-bottom: 0.5em;
        text-align: center;
        span{
          margin: auto;
          display: block;
        }
      }
    }
    &__contact{
      width: 30rem;
      line-height: 1;
      text-align: center;
      a{
        background: #fff;
        color: #2b77e0;
        border: 2px solid #fff;
        display: block;
        width: 100%;
        padding: 2rem 1em;
        @include mq(sm-){
          &:hover{
            background: $main_color;
            color: #fff;
          }
        }
      }
      @include mq(xs){
        margin: auto;
      }
    }
  }
  &_block{
    padding: 8rem 0;
    @include mq(xs){
      padding: 4rem 0;
    }
    &__inner{
      @include mq(xs-){
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
      }
    }
    &__info{

    }
    &__logo{
      font-size: 1rem;
      display: flex;
      align-items: flex-end;
      margin-bottom: 1.5em;
      @include mq(xs){
        margin: 0 auto 1em;
        justify-content: center;
      }
    }
    &__logomark,
    &__logotxt{
      svg{
        width: 100%;
        height: 100%;
      }
    }
    &__logomark{
      width: 6em;
      height: 2.9em;
      svg{
        fill: $main_color;
      }
    }
    &__logotxt{
      width: 28.3em;
      height:1.6em;
    }
    &__adress{
      font-size: 1.4rem;
      @include mq(xs){
        text-align: center;
        margin-bottom: 1em;
      }
    }
    &__link{
      font-size: 1.4rem;
      line-height: 1;
      a{
        display: block;
        position: relative;
        &:before{
          content: "";
          background: $main_color;
          width: 0%;
          height: 2px;
          display: block;
          position: absolute;
          bottom: -0.4em;
          left: 0;
          transition-duration: 0.3s;
        }
        @include mq(sm-){
          &:hover{
            &:before{
              width: 100%;
            }
          }
        }
      }
    }
    &__page{
      display: flex;
      justify-content: flex-end;
      margin-bottom: 2rem;
      li{
        &:first-child{
          margin-right: 1.5rem;
        }
      }
    }
    &__copy{
      @include mq(xs){
        font-size: 1rem;
      }
    }
  }
  .js-anime{
    transform: translateY(0);
    transition-duration: 2s;
  }
}
