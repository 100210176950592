@charset "utf-8";

.slideout-menu {
  background-color: transparent;
  @include mq(sm-){
    position: static;
    width: auto;
    overflow: visible;
  }
}

.slideout-hamburger {
  position: relative;
  display: block;
  width: auto;
  height: auto;
  padding: 0;
  z-index: 110;
  background: transparent;
}
