@charset "utf-8";
/*-------------------------------
  TEXT > CLICK EVENT
-------------------------------*/
.u-pe_none{
  pointer-events: none;
}
/*-------------------------------
  TEXT > COLOR
-------------------------------*/
.u-fcolor_main {
  color: $main_color;
}
.u-fcolor_accent {
  color: $accent_color;
}
.u-fcolor_sub {
  color: $sub_color;
}
/*-------------------------------
  TEXT > SIZE
-------------------------------*/
// smaller
@for $i from 0 through 29{
  .u-fsize_x0#{250+$i*25}{
    font-size: #{0.25+$i*0.025}em;
  }
}
// larger
@for $i from 0 through 72{
  .u-fsize_x#{1000+$i*125}{
    font-size: #{1+$i*0.125}em;
  }
}
/*-------------------------------
  TEXT > WEIGHT
-------------------------------*/
.u-fweight_bk{
  font-weight: 900;
}
.u-fweight_b{
  font-weight: 700;
}
.u-fweight_db{
  font-weight: 600;
}
.u-fweight_m{
  font-weight: 500;
}
.u-fweight_r{
  font-weight: 400;
}
.u-fweight_l{
  font-weight: 300;
}
.u-fweight_t{
  font-weight: 100;
}
.u-fstyle_n{
  font-style: normal;
}
.u-fstyle_i{
  font-style: italic;
}
/*-------------------------------
  TEXT > ALIGN
-------------------------------*/
.u-ta_center{
  text-align: center;
}
.u-ta_left{
  text-align: left;
}
.u-ta_right{
  text-align: right;
}
.u-va_top{
  vertical-align: top;
}
.u-va_middle{
  vertical-align: middle;
}
.u-va_bottom{
  vertical-align: bottom;
}
@include mq(md-lg){
  .u-ta_center_lg{
    text-align: center;
  }
  .u-ta_left_lg{
    text-align: left;
  }
  .u-ta_right_lg{
    text-align: right;
  }
  .u-va_top_lg{
    vertical-align: top;
  }
  .u-va_middle_lg{
    vertical-align: middle;
  }
  .u-va_bottom_lg{
    vertical-align: bottom;
  }
}
@include mq(sm-md){
  .u-ta_center_md{
    text-align: center;
  }
  .u-ta_left_md{
    text-align: left;
  }
  .u-ta_right_md{
    text-align: right;
  }
  .u-va_top_md{
    vertical-align: top;
  }
  .u-va_middle_md{
    vertical-align: middle;
  }
  .u-va_bottom_md{
    vertical-align: bottom;
  }
}
@include mq(xs-sm){
  .u-ta_center_sm{
    text-align: center;
  }
  .u-ta_left_sm{
    text-align: left;
  }
  .u-ta_right_sm{
    text-align: right;
  }
  .u-va_top_sm{
    vertical-align: top;
  }
  .u-va_middle_sm{
    vertical-align: middle;
  }
  .u-va_bottom_sm{
    vertical-align: bottom;
  }
}
@include mq(ss-xs){
  .u-ta_center_xs{
    text-align: center;
  }
  .u-ta_left_xs{
    text-align: left;
  }
  .u-ta_right_xs{
    text-align: right;
  }
  .u-va_top_xs{
    vertical-align: top;
  }
  .u-va_middle_xs{
    vertical-align: middle;
  }
  .u-va_bottom_xs{
    vertical-align: bottom;
  }
}
@include mq(ss){
  .u-ta_center_ss{
    text-align: center;
  }
  .u-ta_left_ss{
    text-align: left;
  }
  .u-ta_right_ss{
    text-align: right;
  }
  .u-va_top_ss{
    vertical-align: top;
  }
  .u-va_middle_ss{
    vertical-align: middle;
  }
  .u-va_bottom_ss{
    vertical-align: bottom;
  }
}
@include mq(sm-){
  .u-ta_center_pc{
    text-align: center;
  }
  .u-ta_left_pc{
    text-align: left;
  }
  .u-ta_right_pc{
    text-align: right;
  }
  .u-va_top_pc{
    vertical-align: top;
  }
  .u-va_middle_pc{
    vertical-align: middle;
  }
  .u-va_bottom_pc{
    vertical-align: bottom;
  }
}
@include mq(sm){
  .u-ta_center_tb{
    text-align: center;
  }
  .u-ta_left_tb{
    text-align: left;
  }
  .u-ta_right_tb{
    text-align: right;
  }
  .u-va_top_tb{
    vertical-align: top;
  }
  .u-va_middle_tb{
    vertical-align: middle;
  }
  .u-va_bottom_tb{
    vertical-align: bottom;
  }
}
@include mq(xs){
  .u-ta_center_sp{
    text-align: center;
  }
  .u-ta_left_sp{
    text-align: left;
  }
  .u-ta_right_sp{
    text-align: right;
  }
  .u-va_top_sp{
    vertical-align: top;
  }
  .u-va_middle_sp{
    vertical-align: middle;
  }
  .u-va_bottom_sp{
    vertical-align: bottom;
  }
}
